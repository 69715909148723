@import 'modules/core/styles/scss/base/bootstrap-extended/_variables';

.formSectionTitle {
  border-bottom: 1px solid $border-color;
  padding: 0 28px 20px 28px;
  width: 100%;
  @media (max-width: (map-get($grid-breakpoints, sm ))) {
    padding: 0 20px 14px 20px;
  }
}

.formSubTitle {
  @extend .formSectionTitle;
  border-bottom: none;
}

.formText {
  color: $secondary;
}

.primaryBorder {
  border: 1px solid $primary;
  border-radius: $border-radius;
}

.formBody {
  padding: 10px 12px 0 12px;
}

.formFooter {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-top: 28px;
  padding-bottom: 20px;
  @media (max-width: (map-get($grid-breakpoints, sm ))) {
    padding-top: 20px;
    padding-bottom: 14px;
  }
}

.mutedText {
  color: $text-muted;
}

.formError {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.857rem;
  color: $red;
}
