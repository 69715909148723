@import 'modules/core/styles/scss/base/bootstrap-extended/_variables';

.brandText {
  color: $primary !important;
  font-weight: 400 !important;
}
.brandSubText {
  font-weight: 700 !important;
}

.defaultPadding {
  padding: 20px 28px !important;
  @media (max-width: (map-get($grid-breakpoints, sm ))) {
    padding: 14px 20px !important;
  }
}

.mobilePadding {
  padding: 14px 20px !important;
}

.defaultSidePadding {
  padding-left: 28px !important;
  padding-right: 28px !important;
  @media (max-width: (map-get($grid-breakpoints, sm ))) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

.defaultVerticalPadding {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  @media (max-width: (map-get($grid-breakpoints, sm ))) {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
  }
}

.defaultTopPadding {
  padding-top: 20px !important;
  @media (max-width: (map-get($grid-breakpoints, sm ))) {
    padding-top: 14px !important;
  }
}

.defaultBottomPadding {
  padding-bottom: 20px !important;
  @media (max-width: (map-get($grid-breakpoints, sm ))) {
    padding-bottom: 14px !important;
  }
}

.statusSelect {
  min-width: 150px;
}

.checkboxMarginRight {
  margin-right: 0.4rem;
}

.borderBottom {
  border-bottom: 1px solid $border-color !important;
}

svg {
  &:focus {
    outline: none;
  }
}
